<template>
        <div>

            <div class="row" v-if="isAdmin">
                <div class="col-xs-12 col-md-6">
                    <button @click="setStatus('open')"  v-show="!status" class="btn btn-bright">View Only Open</button>
                    <button @click="setStatus()"  v-show="status == 'open'" class="btn btn-bright">View All Statuses</button>
                </div>
                <div class="col-xs-12 col-md-6 text-right">
                    <button @click="setSeverity('Standard')" class="btn btn-bright">Standard</button>
                    <button @click="setSeverity('Urgent')" class="btn btn-urgent">Urgent</button>
                    <button @click="setSeverity('Emergency')" class="btn btn-deleteDark">Emergency</button>
                    <button @click="setSeverity()" class="btn btn-light"><i class="fa fa-angle-right"></i>&nbsp;&nbsp;Reset All</button>
                </div>
            </div>


            <span v-show="isLoading($options.name)" style="display: block; text-align: center">
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>

            <router-link tag="div" class="message-row row" v-for="m in maintenanceRequests" :key="m.id" :class="{read: m.status != 'open' }" :to="'maintenance-requests/' + m.id" >

                <div class="status" :class="m.severity.toLowerCase()" v-if="m.status == 'open'">
                    <span class="status-icon">
                    </span>
                    {{m.severity}}
                </div>

                <div v-else class="status" :class="m.status.toLowerCase()">
                    <span class="status-icon">
                    </span>
                    {{m.status}}
                </div>

                <div class="name">
                    <strong>{{m.Contact.first}} {{m.Contact.last}}</strong><br />
                    {{m.Lease.Unit.Address.address}} #{{m.Lease.Unit.number}}<br />
                    {{ m.Lease.Unit.Address | formatAddress }}
                </div>

                <div class="message-content">
                    <em class="subdued"> Last Update {{m.Thread[0].date | formatDate}} </em>
                    <p>{{m.Thread[0].content}}</p>
                </div>

                <div class="message-date">
                    <div class="subdued">{{m.date | formatDate}}</div>
                </div>
            </router-link>
            <div class="message-row" v-if="!maintenanceRequests.length && !isLoading($options.name)">
                No maintenance requests found.
            </div>
        </div>


</template>


<script type="text/babel">
    import Status from '../includes/Messages.vue';
    // import Loader from 'vue-spinner/src/PulseLoader.vue';
    import api from '../../assets/api.js';
    import { mapGetters } from 'vuex';
    export default {
        name: "maintenanceRequests",
        data() {
            return {
                maintenanceRequests:[],
                pagination:{

                },
                status: '',
                severity:'',
            }
        },
        created(){
            this.lease_id = this.$route.params.lease_id || null;
            this.fetchData();
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin'
            })
        },
        components:{
            // Loader,
            Status
        },
        filters:{
        },
        methods: {

            search(){
                this.$router.push('/maintenance-requests?severity=' + this.severity + '&status=' + this.status);
            },
            setStatus(status){
                this.status = status || '';
                this.search();
            },
            setSeverity(severity){
                this.severity = severity || '';
                this.search();
            },
            fetchData(){
                var urlParams = {};
                if(this.lease_id){
                    urlParams.lease_id = this.lease_id;
                    urlParams.offset = 0;
                    urlParams.limit = 30;
                }

                if(this.$route.query.severity){
                    urlParams.severity = this.$route.query.severity;
                    this.severity = this.$route.query.severity;
                }

                if(this.$route.query.status){
                    urlParams.status = this.$route.query.status;
                    this.status = this.$route.query.status;
                }


                this.startLoading(this.$options.name);
                api.get(this, api.MAINTENANCE, urlParams).then(r => {
                    this.maintenanceRequests = r.maintenance;
                    this.pagination = r.pagination;
                });

            }

        },
        watch: {
            '$route' (to, from) {
                this.fetchData(to);
            }
        }
    }
</script>

<style>


</style>
